import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Chocolate = makeShortcode("Chocolate");
const Box = makeShortcode("Box");
const Icon = makeShortcode("Icon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <h1 {...{
      "id": "icon-palette",
      "style": {
        "position": "relative"
      }
    }}>{`Icon Palette`}<a parentName="h1" {...{
        "href": "#icon-palette",
        "aria-label": "icon palette permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h4 {...{
      "id": "this-page-serves-as-a-palette-for-all-icons-specific-to-this-project",
      "style": {
        "position": "relative"
      }
    }}>{`This page serves as a palette for all icons specific to this project.`}<a parentName="h4" {...{
        "href": "#this-page-serves-as-a-palette-for-all-icons-specific-to-this-project",
        "aria-label": "this page serves as a palette for all icons specific to this project permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p><strong parentName="p">{`NOTE: If you're looking for our base set of Icons please see the `}<a parentName="strong" {...{
          "href": "https://dai-ui.now.sh/icons"
        }}>{`Dai.ui Documentation`}</a>{`.`}</strong></p>
    <hr></hr>
    <Chocolate mdxType="Chocolate">
      <Box mdxType="Box">
        <Icon size={4} name="lightbulb" mdxType="Icon" />
        <p>{`lightbulb`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="minus" mdxType="Icon" />
        <p>{`minus`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="moon" mdxType="Icon" />
        <p>{`moon`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="sun" mdxType="Icon" />
        <p>{`sun`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="github" mdxType="Icon" />
        <p>{`github`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="clipboard" mdxType="Icon" />
        <p>{`clipboard`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="folder" mdxType="Icon" />
        <p>{`folder`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="forum" mdxType="Icon" />
        <p>{`forum`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="globe" mdxType="Icon" />
        <p>{`globe`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="laptop" mdxType="Icon" />
        <p>{`laptop`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="pencil" mdxType="Icon" />
        <p>{`pencil`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="people" mdxType="Icon" />
        <p>{`people`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="wrench" mdxType="Icon" />
        <p>{`wrench`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={4} name="maker" mdxType="Icon" />
        <p>{`maker`}</p>
      </Box>
    </Chocolate>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      